<template>
  <v-app>
    <div class="layout-container">
      <v-layout style="background-color: white">
        <v-container>
          <v-layout>
            <div>
              <v-img src="/images/logoEn.png" style="max-width: 90vw"></v-img>
            </div>
            <div style="margin-left:auto">
              <a
                :style="{
                  'font-weight':
                    $store.state.student.lang === 'vi' ? 'bold' : 'normal',
                }"
                @click="$store.commit('student/SET_LANG', 'vi')"
                >VI</a
              >
              |
              <a
                :style="{
                  'font-weight':
                    $store.state.student.lang === 'en' ? 'bold' : 'normal',
                }"
                @click="$store.commit('student/SET_LANG', 'en')"
                >EN</a
              >
            </div>
          </v-layout>
        </v-container>
      </v-layout>
      <div class="circle-container">
        <div class="circle1"></div>
        <div class="circle2"></div>
      </div>
      <div style="position: relative">
        <RedMenu :items="menuItems"></RedMenu>
        <router-view :key="$route.fullPath"></router-view>
        <Footer></Footer>
      </div>
    </div>
    <Snackbar></Snackbar>
  </v-app>
</template>

<script>
export default {
  components: {
    RedMenu: () => import("@/components/RedMenu"),
    Footer: () => import("./Footer"),
    Snackbar: () => import("../../components/Snackbar"),
  },
  data: () => ({
    menuItems: [{ name: "KÍP THI MỞ ĐĂNG KÝ", link: "/" }],
  }),
  mounted() {
    // window.onblur = () => {
    //   console.log('asdsad')
    // }
    console.log(this.$store.state.student);
  },
  methods: {},
};
</script>

<style scoped>
.layout-container {
  background: #f2f2f2;
}

.circle-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.circle1 {
  position: absolute;
  top: 50px;
  width: 800px;
  height: 800px;
  background-color: #f7f7f7;
  border-radius: 50%;
}

.circle2 {
  position: absolute;
  top: 120px;
  width: 660px;
  height: 660px;
  background-color: #fafafa;
  border-radius: 50%;
}
</style>
